import React from "react"
import {Paper, Grid2 as Grid, Typography, Link, Box, useTheme} from '@mui/material';
import VideoItem from "../components/VideoItem";
import minerAnim from '../assets/animations/miner-underconstruction.webm';



export default function UnderConstruction({isMobile}): React.JSX.Element {
  const theme = useTheme();

  return (
    <Paper  sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius:'0'}}>
      <Paper id='under-construction-1' sx={{backgroundColor: '#062e60', width: '100vw', height: isMobile? '100vh':'90vh', borderRadius: '0'}}>
        <Grid container spacing={0} sx={{display: 'flex', justifyContent:  'center', alignItems: 'center', height:'100%', width: '100%'}}>
          <Grid container spacing={0} sx={{ width: '100%', height: '100%'}}>
            <Grid sx={{
                width: '100vw', 
                height: isMobile? '100%':'90vh', 
                overflow: 'hidden', 
                boxShadow: '0px 16px 16px rgba(0,0,0, .5)',
                backgroundColor: 'rgba(0,0,0,.3)',
                zIndex: 2}}>
                <Box sx={{ height: '100%', textAlign:'center', display: 'flex', flexDirection:'column', alignItems:  'center', justifyContent: isMobile? 'flex-start': 'center', marginTop: isMobile ? '20vh': '0'}}>
                  <VideoItem sx={{width: '15%'}} videoUrl={minerAnim} isMobile={isMobile} typeString={'video/webm'}></VideoItem>
                  <Typography variant='h2' sx={{fontSize: '10cqw'}} color={theme.palette.secondary.main}>Pardon Our Dust</Typography>
                  <Typography variant='h6' sx={{fontSize: '3cqw'}} color={theme.palette.secondary.main}>We're still bringing this page out of the mine!</Typography>
                  <Link href='/'><Typography variant="subtitle2" sx={{fontSize: '2cqw'}} color={theme.palette.secondary.main}>Return</Typography></Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  )
}