//*Packages
import React from 'react';
import {Box, Grid2 as Grid, Typography, Link, Paper, useTheme} from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//*Components
import '../styles/global.css'
import Nav from '../components/Nav';
import VideoItem from '../components/VideoItem';
import Section from '../components/Section';
import { TicketItem } from '../components/TicketItem';
import FadeInUpAnimation from '../components/FadeInUp';
import FadeInAnimation from '../components/FadeIn';
//*Assets
import videoUrl from '../assets/videos/tour-compressed.mp4';
import curvySection from '../assets/images/curvy-section.svg';
import rackImg from '../assets/images/miner-rack-sect-2.jpg';
import siteImg from '../assets/images/site-sect2-cropped-1.webp';
import cloudHostingSvg from '../assets/images/cloud-hosting.svg';
import peopleSvg from '../assets/images/people.svg';
import handsSvg from '../assets/images/hands.svg';
import terralinesSvg from '../assets/images/terralines.svg';
import teamImg from '../assets/images/team-on-site.jpg';
import racksImg from '../assets/images/racks-comp.jpg';
import siteRowsImg from '../assets/images/site-rows.jpg';
import poloImg from '../assets/images/polo.jpg';
import teamInspectionImg from '../assets/images/teamwork-inspection.webp';


export default function Home({isActive, isMobile}): React.JSX.Element {
  const theme = useTheme();

  const isTablet = window.innerWidth < 685 && window.innerWidth > 450;

  return (
    <React.Fragment>
    {isMobile ? 
    //Mobile
      <Paper  elevation={0} sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0'}}>
        <Section id='home-1' elevation={0} height={27}>
          <Nav isActive={isActive} isMobile={isMobile}/>
          <Grid container spacing={0} sx={{display: 'flex', justifyContent: 'center', alignItems:'center', width: '100%', height: '100%'}}>
              <Box sx={{
                  width: '100%', 
                  height: '100%', 
                  overflow: 'hidden', 
                  boxShadow: '0px 8px 8px rgba(0,0,0, .5)',
                  zIndex: 2}}>
                <VideoItem sx={{width: '100%', height: '100%', objectFit: 'cover', zIndex: 1}} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'}/>
              </Box>
          </Grid>
        </Section>
        <Section id='home-2' elevation={0} height={isTablet ? 41 : 32} sx={{position: 'relative', overflow: 'hidden',  zIndex: 0}}>
          <Paper id='curvy-section-container' elevation={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', borderRadius:'0', position: 'absolute', zIndex: 1, backgroundColor: theme.palette.primary.main, boxShadow: 'none'}}>
            <img style={{zIndex: 2, position: 'absolute' }} src={curvySection} alt="svg curvy background image" />
            <Grid container sx={{ marginTop: '2cqw', height: "90%", zIndex: 3}}>
              <Grid size={5} sx={{display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent:'center'}} >
                <Box sx={{width: '55cqw', marginBottom: 'calc(10vw + 10vh)', marginLeft:'calc(10vw + 10vw)'}}>
                  <FadeInAnimation delay={0.5}>
                    <Typography variant='h6'  sx={{fontSize: '1.2cqw'}}color={theme.palette.secondary.main}>Satokie Mining</Typography>
                  </FadeInAnimation>
                  <FadeInUpAnimation>
                    <Typography variant='h3' sx={{fontSize: '3.5cqw', textWrap: 'wrap', fontWeight: 'bold'}} color={theme.palette.secondary.main}>WE BUILD, OWN AND OPERATE DATACENTERS THAT SUPPORT THE BITCOIN NETWORK</Typography>
                  </FadeInUpAnimation>
                </Box>
              </Grid>
              <Grid size={7} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <FadeInAnimation delay={0.7}>
                  <Box sx={{marginTop: '20cqw', width: '20cqw', height: '20cqw', borderRadius: '50%', objectFit: 'contain', overflow: 'hidden', display: 'flex'}}>
                      <img style={{objectFit: 'cover', aspectRatio: '1:1'}}src={rackImg} alt="satokie rack image" />
                  </Box> 
                </FadeInAnimation>
                <FadeInAnimation delay={1.0}>
                  <Box sx={{width: '35cqw', height: '35cqw', borderRadius: '50%', objectFit: 'contain', overflow: 'hidden', display: 'flex'}}>
                    <img style={{objectFit: 'cover'}} src={siteImg} alt="satokie site image" />
                  </Box>
                </FadeInAnimation>
              </Grid>
            </Grid>
          </Paper>
        </Section>
        <Section id='home-3' elevation={0} height={55} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '40vw', position: 'relative', zIndex:'0'}}>
          <Box id='grid-box'  sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <FadeInAnimation delay= {0.4}>
              <Grid  sx={{ width: '90cqw', height: '100%', zIndex: 0}} id='ticket-container' wrap='nowrap' container spacing={0}>
              <Grid id='ticket-1' size={6} position='relative'>
                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <Box id='ticket-image-1' sx={{ width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={0} >
                    <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                  </Box>
                  <Box id='ticket-content-1' position='absolute' flexWrap='nowrap' zIndex={0} sx={{ width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <img style={{objectFit: 'contain', width:'6cqw', height:'auto', marginBottom: '2cqw', }} src={cloudHostingSvg} alt="Cloud Hosting Image" />
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.9cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>Hosting Solutions</Typography>
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography sx={{fontSize: '1.5cqw'}} textAlign='center' color={theme.palette.secondary.main}>Have ASICs that need cheap ​energy? Contact us today to get ​access to the cheapest power in ​the industry ran by world class ​operators.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid id='ticket-2' size={6} position='relative'>
                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <Box id='ticket-image-2' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={0} >
                    <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                  </Box>
                  <Box id='ticket-content-2' position='absolute' flexWrap='nowrap' zIndex={0} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <img style={{objectFit: 'contain', width:'6cqw', height:'auto', marginBottom: '3cqw'}} src={peopleSvg} alt="Cloud Hosting Image" />
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.7cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>3rd Party Operators</Typography>
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography sx={{fontSize: '1.5cqw'}} textAlign='center' color={theme.palette.secondary.main}>Looking to develop your own site ​and need an operating team? We ​offer 3rd party operating services ​so you can sleep at night knowing ​your site is taken care of.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid id='ticket-3' size={6} position='relative'>
                <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <Box id='ticket-image-3' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                    <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                  </Box>
                  <Box id='ticket-content-3' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <img style={{objectFit: 'contain', width:'6cqw', height:'auto', marginBottom: '2cqw'}} src={handsSvg} alt="Cloud Hosting Image" />
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography 
                        textAlign='center' 
                        color={theme.palette.secondary.main}
                        sx={{fontSize: '1.7cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>Consulting</Typography>
                    </Box>
                    <Box sx={{width: '80%', height: 'auto'}}>
                      <Typography sx={{fontSize: '1.5cqw'}} textAlign='center' color={theme.palette.secondary.main}>Need help finding a site or structuring a power purchase agreement? We offer our team expertise to anyone looking to develop their own optimized mining facility.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              </Grid>
            </FadeInAnimation>
          </Box>
        </Section>
        <Section id='home-4' elevation={1} height={40} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '20vw', borderRadius: '0', zIndex: 5}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>65</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~2.5EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>5</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>25</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='home-5' elevation={0} height={150} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', borderRadius: '0', zIndex: 0}}>
          <Box id='gallery-container' width={'100%'} height={'100%'} sx={{padding: '6cqw', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={3} width={'100%'} height={'100%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title'>
                            <FadeInAnimation delay ={0.3}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '4cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                              <FadeInUpAnimation delay={0.5}>
                                <Typography variant='h5' sx={{lineHeight: '3.5cqw', fontSize: '2.5cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                              </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{width: '100%', height: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={3} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                      <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                        <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                          <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Team Image" />
                        </FadeInAnimation>
                      </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={3} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.5} sx={{width: '100%', height: '100%'}}>
                                <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Techs Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                              <FadeInAnimation delay={0.7} sx={{width: '100%', height: '100%'}}>
                                <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%'}}  alt="Satokie Site Rows Image" />
                              </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section id='footer-contact' elevation={0} height={20} sx={{backgroundColor: theme.palette.primary.main, marginTop: '2cqw', overflow: 'hidden'}}>
          <Box id='footer-img-container' sx={{width: '100%', height: '80%', position: 'relative'}}>
            <Box id='background-img' sx={{width: '100%', height: '100%', position: 'absolute'}}>
              <img src={poloImg} style={{objectFit: 'cover', height: '100%', width: '100%'}} alt="Satokie Logo on Polo Shirt" />
            </Box>
          </Box>
          <Box id='subfooter-container' sx={{height: '20%', width: '100%', position: 'relative'}}>
            <Box id='subfooter-background-img' sx={{height: '100%', width: '100%', position: 'absolute'}}>
                <img src={terralinesSvg} style={{width: '100%', height: '200%', objectFit: 'cover'}} alt="terralines background svg" />
            </Box>
            <Box id='subfooter-overlay' sx={{height: '100%', width: '90%', marginLeft: '3cqw', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{textAlign: 'center'}}>
                <Link href='mailto:contact@satokiemining.io'>
                  <Typography variant='h6' sx={{ fontSize: '1.5cqw', color: theme.palette.secondary.main, textShadow: '0em .4em .5em rgba(0,0,0, .8) !important', textDecoration: 'underline'}}>contact@satokiemining.io</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="https://x.com/SatokieMining">
                  <XIcon sx={{ fontSize: '1.9cqw', marginTop: 'calc(.65vw + .65vh)', marginRight: '.5cqw', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}}/>
                </Link>
                <Link href="https://www.linkedin.com/company/satokie-mining">
                  <LinkedInIcon sx={{ fontSize: '2cqw', marginTop: '1cqh', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}} />
                </Link> 
              </Box>
            </Box>
          </Box>
        </Section>
      </Paper>
    :
    //Desktop
      <Paper  sx={{position: 'relative', flexGrow: 1, overflowX: 'hidden', borderRadius: '0'}}>
        <Section id='home-1' elevation={0} height={90} sx={{backgroundColor: 'black'}}>
          <Nav isActive={isActive} isMobile={isMobile}/>
          <Grid container spacing={0} sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
            <Grid container spacing={0}>
              <Grid sx={{
                  width: '100vw', 
                  height: '90vh', 
                  overflow: 'hidden', 
                  boxShadow: '0px 16px 16px rgba(0,0,0, .5)',
                  backgroundColor: 'rgba(0,0,0,.9)',
                  zIndex: 2}}>
                <VideoItem sx={{width: '100%', height: '100%', objectFit: 'cover', zIndex: 1}} videoUrl={videoUrl} isMobile={isMobile} typeString={'video/mp4'}/>
              </Grid>
            </Grid>
          </Grid>
        </Section>
        <Section id='home-2' elevation={0} height={90} sx={{maxHeight:'50vw', position: 'relative', overflow: 'hidden'}}>
          <Paper elevation={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', borderRadius:'0', position: 'absolute'}}>
            <img style={{zIndex: 2, position: 'absolute', width: '150%', aspectRatio: '3/1', transform: 'scale(1)'}} src={curvySection} alt="svg curvy background image" />
            <Grid container sx={{ margin: 'calc(4vw+5vh)', width: '100vw', height: "90vh", zIndex: 3}}>
              <Grid size={5} sx={{display: 'flex', flexDirection: 'column',alignItems: 'center', justifyContent:'center'}} >
                <Box sx={{width: 'calc(20vw + 20vh)', marginBottom: 'calc(10vw + 10vh)', marginLeft:'calc(10vw + 10vw)'}}>
                  <FadeInAnimation delay={0.5}>
                    <Typography variant='h6'  sx={{fontSize: 'calc(.6vh + .6vw)'}} color={theme.palette.secondary.main}>Satokie Mining</Typography>
                  </FadeInAnimation>
                  <FadeInUpAnimation delay={0}>
                    <Typography variant='h3' sx={{fontSize: 'calc(1.6vh + 1.6vw)', textWrap: 'wrap', fontWeight: 'bold'}} color={theme.palette.secondary.main}>WE BUILD, OWN AND OPERATE DATACENTERS THAT SUPPORT THE BITCOIN NETWORK</Typography>
                  </FadeInUpAnimation>
                </Box>
              </Grid>
              <Grid size={7} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <FadeInAnimation delay={.7}>
                  <Box sx={{marginTop: '18cqw', backgroundColor: 'black', width: 'calc(10vw + 10vh)', height: 'calc(10vw + 10vh)', borderRadius: '50%', objectFit: 'contain', overflow: 'hidden', display: 'flex'}}>
                    <img style={{objectFit: 'cover', aspectRatio: '1/1', transform: 'scale(1)'}}src={rackImg} alt="satokie rack image" />
                  </Box>   
                </FadeInAnimation>
                <FadeInAnimation delay={1}>
                  <Box sx={{backgroundColor: 'black', width: '30cqw', height: '30cqw', borderRadius: '50%', objectFit: 'contain', overflow: 'hidden', display: 'flex'}}>
                    <img style={{objectFit: 'cover', aspectRatio: '12/9', transform: 'scale(1)'}} src={siteImg} alt="satokie site image" />
                  </Box>
                </FadeInAnimation>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{width: '100%', height: '100%', borderRadius:'0', backgroundColor: theme.palette.primary.main, position: 'absolute', zIndex: 1}}></Paper>
        </Section>
        <Section id='home-3' elevation={0} height={100} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '45vw', position: 'relative', zIndex: 0 }}>
          <Box id='grid-box'  sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <FadeInUpAnimation>
              <Grid  sx={{ width: '80cqw', height: '100%'}} id='ticket-container' wrap='nowrap' container spacing={3}>
                <Grid id='ticket-1' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-1' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-1' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', height:'auto', aspectRatio: '1/1', marginBottom: '2cqw', }} src={cloudHostingSvg} alt="Cloud Hosting Image" />
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.9cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>Hosting Solutions</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Have ASICs that need cheap ​energy? Contact us today to get ​access to the cheapest power in ​the industry ran by world class ​operators.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid id='ticket-2' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-2' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-2' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', aspectRatio: '1/1', height:'auto', marginBottom: '3cqw'}} src={peopleSvg} alt="Cloud Hosting Image" />
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography textAlign='center' color={theme.palette.secondary.main} sx={{fontSize: '1.7cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>3rd Party Operators</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Looking to develop your own site ​and need an operating team? We ​offer 3rd party operating services ​so you can sleep at night knowing ​your site is taken care of.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid id='ticket-3' size={4} position='relative'>
                  <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='ticket-image-3' sx={{width: 'inherit', height: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}position='absolute' zIndex={1} >
                      <TicketItem><svg id="Rxb0gwuOd2RZZaz3" fill="#062e60" viewBox="0 0 109.7036 146.0043"><g id="CTxoJCnxpADD2SgY"><path id="YAYqSJyBaBu2GALT" d="M103.45016064,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,139.75091906 C3.44086003,139.75091906 6.25338984,142.53351946 6.25338984,146.00431795 L103.45016064,146.00431795 C103.45016064,142.56341906 106.23276104,139.75091906 109.70355953,139.75091906 L109.70355953,6.25338984 C106.26266064,6.25338984 103.45016064,3.4707799 103.45016064,0 Z" ></path></g></svg></TicketItem>
                    </Box>
                    <Box id='ticket-content-3' position='absolute' flexWrap='nowrap' zIndex={2} sx={{width: '80%', height: '100%', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}} >
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <img style={{objectFit: 'contain', width:'6cqw', aspectRatio: '1/1', height:'auto', marginBottom: '2cqw'}} src={handsSvg} alt="Cloud Hosting Image" />
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography 
                          textAlign='center' 
                          color={theme.palette.secondary.main}
                          sx={{fontSize: '1.7cqw', fontWeight: 'bold', marginBottom:'calc(.5vw + .5vh)', textWrap: 'nowrap'}}>Consulting</Typography>
                      </Box>
                      <Box sx={{width: '80%', height: 'auto'}}>
                        <Typography sx={{fontSize: '1cqw'}} textAlign='center' color={theme.palette.secondary.main}>Need help finding a site or structuring a power purchase agreement? We offer our team expertise to anyone looking to develop their own optimized mining facility.</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FadeInUpAnimation>
          </Box>
        </Section>
        <Section id='home-4' elevation={0} height={40} sx={{backgroundColor: theme.palette.primary.main, maxHeight: '50vw'}}>
          <Box id='home-4-container'position='relative' width='100%' height='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='center' zIndex={0}>
            <Box id='background-svg-container' position={'absolute'} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', zIndex: 1, overflow: 'hidden'}}>
              <img width='100%' style={{aspectRatio: '1/1'}} src={terralinesSvg} alt="background svg" />
            </Box>
            <Box id='content-container' position={'absolute'} sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', justifyContent:'space-around', width: '100%', height: '100%', zIndex: 2}}>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>140</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>MEGAWATTS DEPLOYED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>~7EH</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>HASHRATE OPERATED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>7</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>SITES MANAGED</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '6cqw', fontWeight: 'bold', textShadow: '0em .3em .5em rgba(0, 0, 0, 0.6) !important', color: theme.palette.secondary.main}}>40</Typography>
                  <Typography sx={{ fontSize: '1.5cqw', fontWeight: 'bold', color: 'rgba(250, 250, 250, .6)'}}>TEAM MEMBERS</Typography>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='home-5' elevation={0} height={150} sx={{backgroundColor: theme.palette.secondary.main, maxHeight: '160vw', zIndex: 0, marginBottom: '8cqw'}}>
          <Box id='gallery-container' width={'100%'} height={'100%'} sx={{padding: '6cqw', zIndex: 1}}>
            <Grid container id='gallery-parent-grid' spacing={6} width={'100%'} height={'100%'} sx={{zIndex: 2}}>
                <Grid id='gallery-left-column'size={6}>
                  <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                    <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                      <Grid id='top-left-text' size={12} sx={{height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='top-left-text-box'>
                          <Box id='top-left-text-title' sx={{marginBottom: '2cqh'}}>
                            <FadeInUpAnimation delay={0}>
                              <Typography variant='h3' sx={{fontWeight: 'bold', fontSize: '3cqw', color: theme.palette.primary.main}}>Setting the Standard for Bitcoin Mining Colocation</Typography>
                            </FadeInUpAnimation>
                          </Box>
                          <Box id='top-left-text-content' sx={{width: '90%'}}>
                            <FadeInUpAnimation delay={0.2}>
                              <Typography variant='h5' sx={{lineHeight: '4cqh', fontSize: '1.6cqw'}}>Our mission is to deliver cutting-edge solutions that optimize energy assets by leveraging Bitcoin Mining as a tool. Satokie Mining aims to embed itself at the intersection of Bitcoin mining and traditional energy markets and assets.</Typography>
                            </FadeInUpAnimation>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid id='bottom-left-img' size={12} sx={{height: '70%', width: '100%', zIndex: 3}}>
                        <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                          <FadeInAnimation delay={0.4} sx={{height: '100%', width: '100%'}}>
                            <img src={racksImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '3/2'}}  alt="Satokie Racks Image" />
                          </FadeInAnimation>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid id='gallery-right-column' size={6} sx={{height: '100%'}}>
                <Box id='collumn-box-container' width={'100%'} height={'100%'}>
                  <Grid id='gallery-left-column-container' container rowSpacing={7.5} width={'100%'} height={'100%'}>
                    <Grid id='top-right-img' size={12} sx={{ height: '30%', width:'100%', zIndex: 3}}>
                        <Box id='bottom-left-img-container' sx={{ height: '100%', width: '100%', zIndex: 4}}>
                          <FadeInAnimation delay={0.3} sx={{ height: '100%', width: '100%'}}>
                            <img src={teamImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Team Image" />
                          </FadeInAnimation>
                        </Box>
                    </Grid>
                    <Grid id='bottom-right-img' size={12} sx={{ height: '70%', width: '100%', zIndex: 3}}>
                      <Box sx={{height: '100%', width: '100%'}}>
                        <Grid container spacing={6} id='bottom-right-img-flex-container' sx={{width: '100%', height: '100%',}}>
                            <Grid size={12} sx={{width: '100%', height: '47%' }}>
                              <Box id='bottom-right-img-1' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={teamInspectionImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '4/1'}}  alt="Satokie Techs Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>
                            <Grid size={12} sx={{width: '100%', height: '47%'}}>
                              <Box id='bottom-right-img-2' sx={{width: '100%', height: '100%'}}>
                                <FadeInAnimation delay={0.3} sx={{width: '100%', height: '100%'}}>
                                  <img src={siteRowsImg} loading='lazy' style={{objectFit: 'cover', width: '100%', height:'100%', aspectRatio: '1/3'}}  alt="Satokie Site Rows Image" />
                                </FadeInAnimation>
                              </Box>
                            </Grid>                        
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section id='footer-contact' elevation={0} height={90} sx={{backgroundColor: theme.palette.primary.main,  overflow: 'hidden', marginTop: '2cqw'}}>
          <Box id='footer-img-container' sx={{width: '100%', height: '80%', position: 'relative'}}>
            <Box id='background-img' sx={{width: '100%', height: '100%', position: 'absolute'}}>
              <img src={poloImg} style={{objectFit: 'cover', height: '100%', width: '100%', aspectRatio: '13/5'}} alt="Satokie Logo on Polo Shirt" />
            </Box>
          </Box>
          <Box id='subfooter-container' sx={{height: '20%', width: '100%', position: 'relative'}}>
            <Box id='subfooter-background-img' sx={{height: '100%', width: '100%', position: 'absolute'}}>
                <img src={terralinesSvg} style={{width: '100%', height: '200%', objectFit: 'cover', aspectRatio: '16/9'}} alt="terralines background svg" />
            </Box>
            <Box id='subfooter-overlay' sx={{height: '100%', width: '90%', marginLeft: '3cqw', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{textAlign: 'center'}}>
                <Link href="mailto:contact@satokiemining.io">
                  <Typography variant='h6' sx={{ fontSize: '1.5cqw', color: theme.palette.secondary.main, textShadow: '0em .4em .5em rgba(0,0,0, .8) !important', textDecoration: 'underline'}}>contact@satokiemining.io</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="https://x.com/SatokieMining">
                  <XIcon sx={{ fontSize: '1.9cqw', marginTop: 'calc(.65vw + .65vh)', marginRight: '.5cqw', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}}/>
                </Link>
                <Link href="https://www.linkedin.com/company/satokie-mining">
                  <LinkedInIcon sx={{ fontSize: '2cqw', marginTop: '1cqh', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}} />
                </Link> 
              </Box>
            </Box>
          </Box>
        </Section>
      </Paper>
    }
    </React.Fragment>
  );
}
