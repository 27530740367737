import React from 'react';
import Section from "../components/Section"
import {Box, Paper, useTheme, Typography, Grid2 as Grid, Link}from "@mui/material";
import BitcoinAnimation from '../components/BitcoinAnimation';
import Nav from '../components/Nav';
import teamworkImg from '../assets/images/teamwork.webp';
import terralinesSvg from '../assets/images/terralines.svg';
import HorizontalTicket from '../components/HorizontalTicket';
import TitleAndSeparator from '../components/TitleAndSeparator';
import jayTaylorImg from '../assets/images/jay-taylor-tailgate.webp';
import taylorYuckYuckImg from '../assets/images/taylor-yuck-yuck.webp';
import powerStationImg from '../assets/images/power-station.webp';
import powerLinesImg from '../assets/images/power-lines.webp';
import computerTerminalImg from '../assets/images/computer-terminal.webp';
import closeUpMinersImg from '../assets/images/close-up-miners.webp';
import partnersImg from '../assets/images/partners.webp';
import containerImg from '../assets/images/container.webp';
import computerRacksImg from '../assets/images/computer-racks.webp';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import poloImg from '../assets/images/polo.jpg';


export default function Services ({isActive, isMobile}): React.JSX.Element {
  const theme = useTheme();


  return (
    <React.Fragment>
      <Paper elevation={0} sx={{position: 'relative', backGroundColor: '#f8f5ef'}}>
        <Section id='services-1' height={90} sx={{position: 'relative'}}>
          <Nav isActive={isActive} isMobile={isMobile}></Nav>
          <Box sx={{position: 'absolute', width: '100%', height: '100%'}}>
            <img  style={{width: '100%', height: '100%', objectFit: 'cover', zIndex: 0}} src={teamworkImg} alt="Image of team investigating an electrical box in the mining container" />
          </Box>
          <Box sx={{width: '100%', height: '100%', position: 'absolute', zIndex: 1, backgroundColor: theme.palette.primary.main, opacity: .35, boxShadow: '0px 16px 16px rgba(0,0,0, .5)'}}></Box>
        </Section>
        <Section id='services-2' height={100} sx={{position:'relative'}}>
          <Box sx={{position: 'absolute', width: '100%', height: '100%', zIndex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
            <img style={{width: '200%', height: '200%', opacity: .1}} src={terralinesSvg} alt="terralines svg background" />
          </Box>
          <Box sx={{position: 'absolute', width: '100%', height: '100%', backgroundColor: theme.palette.primary.main, zIndex: 0}}>
            <Box sx={{width: '100%', height: '20%', margin: '3cqw', marginBottom: '2cqw', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <Typography variant='h2' textAlign='center' sx={{display: 'inline', fontWeight: 'bold', fontSize: '4cqw', color: theme.palette.secondary.main, margin: '.5cqw', marginBottom: '1cqw', textShadow: '0em .1em .5em rgba(0, 0, 0, 0.6) !important'}}> Discover </Typography>
              <Typography textAlign='center' sx={{display: 'inline', fontWeight: 'bold', fontSize: '4cqw', borderBottom: '.30cqw solid', margin: '.5cqw', textShadow: '0em .1em .5em rgba(0, 0, 0, 0.6) !important', color: '#f6eee0'}}>Our Services</Typography>
            </Box>
            <Box sx={{width: '50%', height: '65%', margin: '3cqw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <HorizontalTicket id='ticket-1' content='ASIC REPAIR' animationDelay={0}></HorizontalTicket>
              <HorizontalTicket id='ticket-2' content='SITE ACQUISITION' animationDelay={0.2}></HorizontalTicket>
              <HorizontalTicket id='ticket-3' content='ASIC/INFRASTRUCTURE PROCUREMENT' animationDelay={0.4}></HorizontalTicket>
              <HorizontalTicket id='ticket-4' content='CONSULTATIONS' animationDelay={0.6}></HorizontalTicket>
            </Box>
          </Box>
          <Box sx={{position: 'absolute', width: '100%', height: '100%', zIndex: 2, margin: '3cqw', marginTop: '6cqw'}}>
            <BitcoinAnimation></BitcoinAnimation>
          </Box>
        </Section>
        <Section id='services-3' elevation={0} height={80} sx={{position: 'relative', backgroundColor: '#f8f5ef', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: 'none'}}>
          <Box sx={{height: '100%', width: '80%', transform: 'scale(1)'}}>
            <Grid container spacing={0} sx={{width: '100%', height: '100%'}}>
              <Grid container size={12} sx={{ marginTop: '6cqw', marginLeft: '6cqw', marginRight: '6cqw'}}>
                <Grid size={4} sx={{height: '100%'}}>
                  <TitleAndSeparator 
                    title='Resources Optimization' 
                    content='Collaborations allow companies to ​share and optimize resources, reducing ​costs and improving efficiency.'
                  />
                </Grid>
                <Grid size={4} sx={{ height: '100%'}}>
                  <TitleAndSeparator
                    title='Scalability'
                    content='Partnerships enable faster and more ​scalable expansion of mining ​operations, essential for staying ​competitive.'
                  />
                </Grid>
                <Grid size={4} sx={{ height: '100%'}}>
                  <TitleAndSeparator
                    title='Innovation'
                    content='Working with other tech and infrastructure ​companies fosters innovation and the ​implementation of cutting-edge ​technologies.'
                  />
                </Grid>
              </Grid>
              <Grid container size={12} sx={{marginBottom: '6cqw', marginLeft: '6cqw', marginRight: '6cqw'}}>
                <Grid size={4} sx={{height: '100%'}}>
                  <TitleAndSeparator
                    title='Risk Mitigation'
                    content='Shared investments and joint ventures ​distribute risks, making operations ​more resilient to market fluctuations.'
                  />
                </Grid>
                <Grid size={8} sx={{ height: '100%'}}>
                  <TitleAndSeparator
                    title='Enhanced Capabilities'
                    content='Partnering with specialized firms enhances overall capabilities and service offerings. - These ​factors collectively contribute to more robust and efficient mining operations, driving growth ​and profitability.'  
                  />
                </Grid>
                

              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section id='services-4' elevation={0} height={150} sx={{position: 'relative', backgroundColor: '#f8f5ef', zIndex: 0,}}>
          <Box id="background" sx={{position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', zIndex: 1}}>
            <Box id='top-svg' sx={{height: '100%', width: '100%', zIndex: 2}}>
              <img src="" alt="" />

            </Box>
            <Box id='bottom-svg' sx={{height: '100%', width: '100%', zIndex: 2}}>
              <img src="" alt="" />
            </Box>
          </Box>
          <Box id='image-gallery' sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', position: 'absolute', zIndex: 3, padding: '2cqw'}}>
            <Box id='top-half' sx={{width: '80%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 0 .5cqw 0' }}>
                <Box id='left-side-top' sx={{width: '40%', height: '100%'}}>
                  <Box id='top' sx={{width: '100%', height: '50%', padding: '0cqw .5cqw .5cqw 0cqw'}}>
                    <img src={jayTaylorImg} style={{  width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover'}} alt="Jay and Taylor sitting on a tailgate" />
                  </Box>
                  <Box id='bottom' sx={{width: '100%', height: '50%', padding: '.5cqw .5cqw 0cqw 0cqw'}}>
                  <img src={taylorYuckYuckImg} style={{ width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover'}} alt="Taylor laughing with containers in the distance" />
                  </Box>
                </Box>
                <Box id='right-side-top' sx={{width: '60%', height: '100%',}}>
                  <Box id='right-top-container' sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <Box id='left' sx={{ padding: '0 .5cqw', width: '55%', height: '100%',}}>
                        <img src={powerLinesImg} style={{width: '100%', height: '100%', aspectRatio: '9/16', objectFit: 'cover'}} alt="Power Station" />
                    </Box>
                    <Box id='double-right' sx={{width: '45%', height: '100%',}}>
                      <Box id='top' sx={{width: '100%', height: '50%', padding: '0 0 .5cqw .5cqw'}}>
                        <img src={powerStationImg} alt="Power Lines" style={{width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover'}}/>
                      </Box>
                      <Box id='bottom' sx={{width: '100%', height: '50%', padding: '.5cqw 0 0 .5cqw'}}>
                        <img src={computerTerminalImg} alt="Computer Terminal" style={{width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover'}}/>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            </Box>
            <Box id ='bottom-half' sx={{width: '80%', height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.5cqw 0 0 0' }}>
              <Box id='left-side-bottom' sx={{width: '40%', height: '100%'}}>
                <Box id='top' sx={{width: '100%', height: '50%', padding: '0cqw .5cqw .5cqw 0cqw'}}>
                  <img src={closeUpMinersImg} style={{  width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover'}} alt="Jay and Taylor sitting on a tailgate" />
                </Box>
                <Box id='bottom' sx={{width: '100%', height: '50%', padding: '.5cqw .5cqw 0cqw 0cqw'}}>
                <img src={partnersImg} style={{ width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover'}} alt="Taylor laughing with containers in the distance" />
                </Box>
                </Box>
                <Box id='right-side-bottom' sx={{width: '60%', height: '100%',}}>
                  <Box id='top' sx={{width: '100%', height: '75%', padding: '0 0 .5cqw .5cqw'}}>
                    <img src={containerImg} alt="Power Lines" style={{width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover'}}/>
                  </Box>
                  <Box id='bottom' sx={{width: '100%', height: '25%', padding: '.5cqw 0 0 .5cqw'}}>
                    <img src={computerRacksImg} alt="Computer Terminal" style={{width: '100%', height: '100%', aspectRatio: '1/1', objectFit: 'cover'}}/>
                  </Box>
                </Box>
            </Box>
          </Box>
        </Section>
        <Section id='footer-contact' elevation={0} height={90} sx={{backgroundColor: theme.palette.primary.main,  overflow: 'hidden'}}>
          <Box id='footer-img-container' sx={{width: '100%', height: '80%', position: 'relative'}}>
            <Box id='background-img' sx={{width: '100%', height: '100%', position: 'absolute'}}>
              <img src={poloImg} style={{objectFit: 'cover', height: '100%', width: '100%'}} alt="Satokie Logo on Polo Shirt" />
            </Box>
          </Box>
          <Box id='subfooter-container' sx={{height: '20%', width: '100%', position: 'relative'}}>
            <Box id='subfooter-background-img' sx={{height: '100%', width: '100%', position: 'absolute'}}>
                <img src={terralinesSvg} style={{width: '100%', height: '200%', objectFit: 'cover'}} alt="terralines background svg" />
            </Box>
            <Box id='subfooter-overlay' sx={{height: '100%', width: '90%', marginLeft: '3cqw', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{textAlign: 'center'}}>
                <Link href="mailto:contact@satokiemining.io">
                  <Typography variant='h6' sx={{ fontSize: '1.5cqw', color: theme.palette.secondary.main, textShadow: '0em .4em .5em rgba(0,0,0, .8) !important', textDecoration: 'underline'}}>contact@satokiemining.io</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="https://x.com/SatokieMining">
                  <XIcon sx={{ fontSize: '1.9cqw', marginTop: 'calc(.65vw + .65vh)', marginRight: '.5cqw', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}}/>
                </Link>
                <Link href="https://www.linkedin.com/company/satokie-mining">
                  <LinkedInIcon sx={{ fontSize: '2cqw', marginTop: '1cqh', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}} />
                </Link> 
              </Box>
            </Box>
          </Box>
        </Section>
      </Paper>
    </React.Fragment>

  )
}