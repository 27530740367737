import { styled, Box } from "@mui/material";

export const TicketItem = styled(Box)(({theme}) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  borderRadius: '0',
  textAlign: 'center',
  objectFit: 'contain',
  width: '100%',
  height: 'auto',
  color: theme.palette.text.secondary, ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  }),
}));