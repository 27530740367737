import React from "react";
import { Box, useTheme, Typography  } from "@mui/material";
import SlideFromLeftAnimation from "./SlideFromLeft";

interface TitleAndSeparatorProps {
  title: string,
  content: string
}

export default function TitleAndSeparator({title, content}: TitleAndSeparatorProps): React.JSX.Element{
  const theme = useTheme();

  return (
    <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', overflow: 'hidden'}}>
      <Box sx={{height: '48%', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
        <SlideFromLeftAnimation sx={{width: '100%', overflow: 'hidden'}}>
          <Typography sx={{textAlign: 'left', width: '100%', color: 'black', fontWeight: 'bold', fontSize: '1.8cqw', overflow: 'hidden', zIndex: 1}}>{title}</Typography>
        </SlideFromLeftAnimation>

      </Box>
      <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#f8f5ef', zIndex: 0}}>
        <hr style={{ margin: 0, marginTop: '1cqw', marginBottom: '1cqw', width: '70%', height: '.001em', border: `.001em solid black`}}/>
      </Box>
      <Box sx={{height: '48%', width: '80%'}}>
        <SlideFromLeftAnimation sx={{ width: '100%',}}>
          <Typography sx={{textAlign: 'left', width: '100%', color: 'black', fontSize: '.8cqw', lineHeight: '2', zIndex: 2}}>{content}</Typography>
        </SlideFromLeftAnimation>
      </Box>
    </Box>

  );
}