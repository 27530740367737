import React from 'react';
import Logo from './Logo';
import NavItems from './NavItems';
import {Grid2 as Grid }from '@mui/material';

export default function Nav({isActive, isMobile}): React.JSX.Element {
  return (
    <>
      <Grid container sx={{display: 'flex', justifyContent: 'flex-start', position: 'absolute'}}>
        <Logo isMobile={isMobile}/>
      </Grid>
      <Grid container spacing={0} sx={{display: 'flex', justifyContent: 'flex-end', width: '100cqw'}}>
        <NavItems isActive={isActive} isMobile={isMobile} />
      </Grid>
    </>
  )
}