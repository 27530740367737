import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

const BitcoinAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isMouseDown, setIsMouseDown] = useState(false); // Track mouse down state
  const [mesh, setMesh] = useState<THREE.Mesh | null>(null);

  const rotationSpeed = 0.0025; // Speed of auto-rotation for Y-axis
  const dragSensitivity = 0.01; // Sensitivity for drag movement

  useEffect(() => {
    if (!canvasRef.current) return;

    // Scene, camera, and renderer setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);  // Set initial aspect ratio
    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current, 
      alpha: true, // Enable alpha for transparent background
    });
    
    // Set initial size of the canvas based on window size
    renderer.setSize(window.innerWidth, window.innerHeight);  // Set initial canvas size to match window
    renderer.setClearColor(0x000000, 0); // Set clear color with transparency

    // Load the Bitcoin texture and create a plane mesh for a single coin
    const textureLoader = new THREE.TextureLoader();
    const bitcoinTexture = textureLoader.load('/bitcoin-logo.png');
    const geometry = new THREE.PlaneGeometry(10, 10);
    const material = new THREE.MeshBasicMaterial({ map: bitcoinTexture, side: THREE.DoubleSide, transparent: true });
    const bitcoinMesh = new THREE.Mesh(geometry, material);

    // Center the mesh
    scene.add(bitcoinMesh);
    camera.position.z = 10;
    camera.position.x = -5;
    camera.position.y = 0; // Zoom in closer by reducing the camera position on the Z-axis
    scene.add(new THREE.AmbientLight(0xffffff, 1));

    let lastMousePosition = { x: 0, y: 0 };  // For tracking mouse movement when clicked

    // Function to handle mouse down
    const onMouseDown = (event: MouseEvent) => {
      setIsMouseDown(true);  // Set mouse state to down
      lastMousePosition = { x: event.clientX, y: event.clientY };
    };

    // Function to handle mouse up
    const onMouseUp = () => {
      setIsMouseDown(false);  // Set mouse state to up (resume rotation)
    };

    // Function to handle mouse move (dragging)
    const onMouseMove = (event: MouseEvent) => {
      if (isMouseDown) {
        // Calculate the change in mouse position
        const deltaX = event.clientX - lastMousePosition.x;
        const deltaY = event.clientY - lastMousePosition.y;

        // Apply the change to the mesh's rotation (dragging effect)
        bitcoinMesh.rotation.y += deltaX * dragSensitivity;  // Drag on Y-axis (horizontal)

        // Update the last mouse position
        lastMousePosition = { x: event.clientX, y: event.clientY };
      }
    };

    // Add event listeners for mouse interaction (mousedown, mouseup, mousemove)
    canvasRef.current.addEventListener('mousedown', onMouseDown);
    canvasRef.current.addEventListener('mouseup', onMouseUp);
    canvasRef.current.addEventListener('mousemove', onMouseMove);

    // Animation loop to automatically rotate the coin only on the Y-axis when not dragging
    const animate = () => {
      requestAnimationFrame(animate);
      
      // Auto-rotate on the Y-axis when mouse is not down (dragging)
      if (!isMouseDown) {
        bitcoinMesh.rotation.y += rotationSpeed; // Rotate around the Y-axis
      }

      renderer.render(scene, camera); // Render the scene
    };

    animate();

    // Resize handler
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;  // Update camera's aspect ratio
      camera.updateProjectionMatrix();  // Apply the new aspect ratio
      renderer.setSize(width, height);  // Resize the renderer to match the new window size
      
      if(mesh){
        const scaleFactor = width / 1920;
        mesh.scale.set(scaleFactor, scaleFactor * 3, 1);
      }
    
    };
    window.addEventListener('resize', onResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', onResize);
      canvasRef.current?.removeEventListener('mousedown', onMouseDown);
      canvasRef.current?.removeEventListener('mouseup', onMouseUp);
      canvasRef.current?.removeEventListener('mousemove', onMouseMove);
      renderer.dispose();
    };
  }, [isMouseDown, mesh]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        background: 'transparent',
        display: 'block',
      }}
    />
  );
};

export default BitcoinAnimation;
